import user from "../common/user";
import payment from "../common/payment";
import vehicle from "../common/vehicle";
import { modelNameUppercase } from "../../../utils/modelNameUppercase";
import { getPriceFormatted } from "../../../utils/currency";
import { formatMonthlyPrice } from "../../../utils/vehicle";
import {
  getAllReservations,
  createPaymentIntent,
  requestPreApproval,
  requestPreApprovalDropdowns
} from "../../../api/reservations";

import dayjs from "dayjs";

function strOrInt(val) {
  const num = parseInt(val, 10);
  return num ? num : val;
}

function tryParseDate(date) {
  const utcRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z$/;
  const alreadyInDateFormat = utcRegex.test(date);
  if (alreadyInDateFormat) {
    return dayjs(this.value).format("DD/MM/YYYY");
  }
  return date;
}

const state = {
  // FIXME: load from db
  reservationCost: 500,
  reservations: [],
  currReservation: {
    id: "",
    vehicle: {},
    user: {
      personal: {
        name: "",
        email: "",
        phone: "",
        birthdate: ""
      },
      receipt: {
        nif: "",
        address: "",
        zipCode: "",
        location: ""
      },
      dealer: {
        district: "",
        name: ""
      }
    },
    retake: {
      plate: "",
      brand: "",
      model: "",
      plateDate: "",
      fuel: "",
      transmission: ""
    }
  },
  reservationPreApproval: {
    creditEntity: 1,
    reservationId: "",
    userInfo: {
      identification: {
        type: 1,
        value: "",
        controlValue: "",
        expDate: ""
      },
      nationality: "",
      maritalStatus: "",
      profissionalActivity: {
        occupation: "",
        company: "",
        occupationTime: "",
        contractType: "",
        monthlyEarnings: ""
      },
      housingActivity: {
        housingType: "",
        housingTime: ""
      },
      bankInfo: {
        accountTime: "",
        nib: ""
      }
    }
  },
  preApprovalDropdowns: {}
};

const getters = {
  getFlattenData: state => {
    return {
      housingType:
        state.reservationPreApproval.userInfo.housingActivity.housingType,
      contractType:
        state.reservationPreApproval.userInfo.profissionalActivity.contractType,
      maritalStatus: state.reservationPreApproval.userInfo.maritalStatus
    };
  },
  getNationality: state => state.reservationPreApproval.userInfo.nationality,
  getMaritalStatus: state =>
    state.reservationPreApproval.userInfo.maritalStatus,
  getOccupation: state =>
    state.reservationPreApproval.userInfo.profissionalActivity.occupation,
  getOccupationTime: state =>
    state.reservationPreApproval.userInfo.profissionalActivity.occupationTime,
  getCompany: state =>
    state.reservationPreApproval.userInfo.profissionalActivity.company,
  getContractType: state =>
    state.reservationPreApproval.userInfo.profissionalActivity.contractType,
  getMonthlyEarnings: state =>
    state.reservationPreApproval.userInfo.profissionalActivity.monthlyEarnings,
  getHousingType: state =>
    state.reservationPreApproval.userInfo.housingActivity.housingType,
  getHousingTime: state =>
    state.reservationPreApproval.userInfo.housingActivity.housingTime,
  getAccountTime: state =>
    state.reservationPreApproval.userInfo.bankInfo.accountTime,
  getIban: state => state.reservationPreApproval.userInfo.bankInfo.nib,
  getIdType: state =>
    state.reservationPreApproval.userInfo.identification?.type,
  getIdValue: state =>
    state.reservationPreApproval.userInfo.identification?.value,
  getIdControlValue: state =>
    state.reservationPreApproval.userInfo.identification?.controlValue,
  getIdExpireDate: state =>
    state.reservationPreApproval.userInfo.identification?.expDate,
  getVehicleTotalPriceOrPvpFormatted: state => {
    const price =
      state.currReservation?.vehicle?.pricePvp ||
      state.currReservation?.vehicle?.totalPrice;
    return getPriceFormatted(Number(price).toFixed(2));
  },
  getReservationWithFinance: state => {
    return !!state.currReservation?.vehicle?.carDetailFinance?.capitalFinance;
  },
  getFormattedTimeLimit: state => {
    const date = state.timeLimit
      .toLocaleString("pt", {
        month: "long",
        weekday: "long",
        day: "numeric"
      })
      .split(" ");

    date[date.length - 1] =
      date[date.length - 1].charAt(0).toUpperCase() +
      date[date.length - 1].slice(1);

    return date.join(" ");
  },
  getReservationRetake: state => {
    return state.currReservation?.retake?.status === 2
      ? !!state.currReservation?.retake?.actualValuation
      : !!state.currReservation?.retake?.initialValuation;
  },
  getReservations: state => state.reservations,
  getCurrentReservation: state => state.currReservation,
  getReservationReceipt: state => state.currReservation.user.receipt,
  getReservationReceiptWithLabels: state => {
    const {
      nif,
      address,
      zipCode,
      location
    } = state.currReservation.user.receipt;
    return {
      NIF: nif,
      "Morada, Rua, Travessa": address,
      "Código Postal": zipCode,
      Localidade: location
    };
  },
  getReservationUser: state => state.currReservation.user.personal,
  getReservationUserWithLabel: state => {
    const {
      name,
      email,
      phone,
      birthdate
    } = state.currReservation.user.personal;
    return {
      Nome: name,
      Email: email,
      Telemóvel: phone,
      "Data Nascimento": tryParseDate(birthdate)
    };
  },
  getReservationFinanceWithLabels: state => {
    const { taeg, monthlyPrice } = state.currReservation.vehicle;
    const {
      months,
      depositValue,
      capitalFinance,
      residualValue
    } = state.currReservation.vehicle.carDetailFinance;
    const resp = {
      "Valor Mensal": formatMonthlyPrice(monthlyPrice),
      "Número de Meses": months,
      TAEG: Number(taeg).toFixed(1) + "%",
      "Montante Financiado": Number(capitalFinance).toFixed(2) + " €",
      "Entrada Inicial": getPriceFormatted(depositValue)
    };
    if (residualValue) {
      resp["Valor Residual"] = `${residualValue} €`;
    }
    return resp;
  },
  getReservationVehicleGeneralWithLabel(state, getters) {
    return {
      "Valor PVP": getters.getVehicleTotalPriceOrPvpFormatted,
      "Valor Sinal de Reserva": "500" + " €"
    };
  },
  getReservationDealerWithLabels: (_, getters) => code => {
    const {
      designation: name,
      district,
      municipality
    } = getters.getDealersArray?.find(d => d.dealerCode === code);
    return {
      Distrito: district,
      Concessionario: `${name} - ${municipality}`
    };
  },
  getReservationVehicle: state => state.currReservation.vehicle,
  getReservationRetakeLeftWithLabels: state => {
    const { plate, brand, model } = state.currReservation.retake;
    return {
      Matricula: plate,
      Marca: brand,
      Modelo: model
    };
  },
  getReservationCurrentRetakeSubmitted: state => {
    return state.currReservation.retake.submitted;
  },
  getReservationRetakeRightWithLabels: state => {
    const { plateDate, version } = state.currReservation.retake;
    return {
      "Data de Matrícula": plateDate,
      Versão: version
      // Combustivel: fuel,
      // Transmissao: transmission
    };
  },
  getReservationRetakeValue: state =>
    "€ " + Number(state.currReservation.retake.value).toFixed(2),
  getReservationCost: state => state.reservationCost,
  getReservationPreApproval: state => state.reservationPreApproval,
  getPreApprovalDropdowns: state => state.preApprovalDropdowns,
  getPreApprovalIsSubmited: state => {
    return state.currReservation.isPreApprovalSubmitted;
  },
  getPreApprovalPayload: state => {
    return state.currReservation.payload;
  }
};

const mutations = {
  setReservations(state, reservations) {
    state.reservations = reservations.map(
      ({
        finance,
        receipt,
        retake,
        user,
        vehicle,
        id,
        isPreApprovalSubmitted,
        payload,
        dealerCode,
        tooltipDescription
      }) => ({
        id,
        user: {
          personal: {
            name: user.name,
            email: user.userEmail,
            phone: user.phoneNumber,
            birthdate: user.birthdate
          },
          receipt
        },
        dealerCode,
        retake: {
          ...retake,
          fuel: "NaN",
          transmission: "NaN",
          value:
            retake.status === 2
              ? retake.actualValuation
              : retake.initialValuation,
          plate: retake.licensePlate
        },
        vehicle: {
          preApprovalStatus: finance.preApprovalStatus,
          totalPrice: vehicle.totalPrice,
          commercialDescription: vehicle.description,
          fuel: "NaN",
          model: modelNameUppercase(vehicle.model),
          monthlyPrice: finance.monthlyValue,
          pricePvp: finance.pricePvp,
          taeg: finance.taeg,
          legalInfo: finance.legalInfo,
          transmission: "NaN",
          vin: vehicle.vin,
          tooltipDescription,
          imageUrl: vehicle.imageUrl,
          carDetailCard: {
            year: "NaN",
            model: vehicle.model,
            commercialDescription: vehicle.description,
            fuel: "NaN",
            transmission: "NaN",
            pricePvp: finance.pricePvp,
            vin: vehicle.vin,
            imageUrl: "",
            taeg: finance.taeg,
            monthlyPrice: finance.monthlyValue,
            tooltipDescription: {
              depositValue: finance.depositValue,
              capitalFinance: finance.financedValue,
              mtic: finance.financedValue,
              tan: "NaN",
              months: finance.nbrMonths
            },
            favorite: false
          },
          carDetailFinance: {
            months: finance.nbrMonths,
            depositValue: finance.depositValue,
            capitalFinance: finance.financedValue,
            residualValue: finance.residualValue
          }
        },
        isPreApprovalSubmitted: isPreApprovalSubmitted,
        payload: payload
      })
    );
  },
  setCurrentReservation(state, id) {
    state.currReservation = state.reservations.find(r => r.id === id);
    if (state.currReservation.payload?.userInfo) {
      this.commit(
        "setPreApprovalUserInfo",
        state.currReservation.payload.userInfo
      );
    } else {
      this.commit("setPreApprovalUserInfo", {});
    }
  },
  setPreApprovalUserInfo(state, obj) {
    state.reservationPreApproval.userInfo = {
      ...state.reservationPreApproval.userInfo,
      ...obj
    };
  },
  setPreApprovalReservationId(state, id) {
    state.reservationPreApproval.reservationId = id;
  },
  setPreApprovalCreditEntity(state, entity) {
    state.reservationPreApproval.creditEntity = entity;
  },
  setPreApproval(state, entity) {
    state.reservationPreApproval = entity;
  },
  setPreApprovalDropdowns(state, entity) {
    state.preApprovalDropdowns = entity;
  },
  setNationality(state, n) {
    state.reservationPreApproval.userInfo.nationality = n;
  },
  setMaritalStatus(state, m) {
    const num = strOrInt(m);
    state.reservationPreApproval.userInfo.maritalStatus = num;
  },
  setOccupation(state, val) {
    state.reservationPreApproval.userInfo.profissionalActivity.occupation = val;
  },
  setOccupationTime(state, val) {
    const num = strOrInt(val);
    state.reservationPreApproval.userInfo.profissionalActivity.occupationTime = num;
  },
  setCompany(state, val) {
    state.reservationPreApproval.userInfo.profissionalActivity.company = val;
  },
  setContractType(state, val) {
    const num = strOrInt(val);
    state.reservationPreApproval.userInfo.profissionalActivity.contractType = num;
  },
  setMonthlyEarnings(state, val) {
    const num = strOrInt(val);
    state.reservationPreApproval.userInfo.profissionalActivity.monthlyEarnings = num;
  },
  setHousingType(state, val) {
    const num = strOrInt(val);
    state.reservationPreApproval.userInfo.housingActivity.housingType = num;
  },
  setHousingTime(state, val) {
    const num = strOrInt(val);
    state.reservationPreApproval.userInfo.housingActivity.housingTime = num;
  },
  setAccountTime(state, val) {
    const num = strOrInt(val);
    state.reservationPreApproval.userInfo.bankInfo.accountTime = num;
  },
  setIban(state, val) {
    state.reservationPreApproval.userInfo.bankInfo.nib = val;
  },
  setReservationId(state, id) {
    state.reservationPreApproval.reservationId = id;
  },
  setCreditEntity(state, c) {
    state.reservationPreApproval.creditEntity = c;
  },
  setIdType(state, val) {
    state.reservationPreApproval.userInfo.identification.type = val;
  },
  setIdValue(state, val) {
    state.reservationPreApproval.userInfo.identification.value = val;
  },
  setIdControlValue(state, val) {
    state.reservationPreApproval.userInfo.identification.controlValue = val;
  },
  setIdExpDate(state, val) {
    state.reservationPreApproval.userInfo.identification.expDate = val;
  }
};

const actions = {
  async storeReservations({ commit }) {
    try {
      const res = await getAllReservations();
      // FIXME: store data in user and vehicle?
      commit("setReservations", res.data.data);
    } catch (e) {
      console.error(`Failed fetching reservations with: ${e.toString()}`);
    }
  },
  async generatePaymentIntent({ commit }, payload) {
    console.log("Generating Payment Intent", payload, commit);

    try {
      return await createPaymentIntent(payload);
    } catch (err) {
      console.error(err);
    }
  },
  setPreApprovalPayload({ commit }, obj) {
    if (obj.userInfo) {
      commit("setPreApprovalUserInfo", obj.userInfo);
    } else if (obj.reservationId) {
      commit("setPreApprovalReservationId", obj.reservationId);
    } else if (obj.creditEntity) {
      commit("setPreApprovalCreditEntity", obj.creditEntity);
    }
  },
  async requestPreApprovalPayload({ state, commit }) {
    try {
      const response = await requestPreApproval(state.reservationPreApproval);
      commit("setPreApproval", {});
      return response;
    } catch (err) {
      console.error(err);
    }
  },
  async requestPreApprovalDropdowns({ commit }) {
    try {
      const response = await requestPreApprovalDropdowns();
      commit("setPreApprovalDropdowns", response.data.data);
      return response.data.data;
    } catch (err) {
      console.error(err);
    }
  }
};

export default {
  modules: {
    user,
    payment,
    vehicle
  },
  state,
  getters,
  mutations,
  actions
};
