import { getDropdowns } from "../../api/dropdowns";
import { modelNameUppercase } from "../../utils/modelNameUppercase";
export const DropdownType = {
  Deposit: "deposit",
  MonthlyRate: "monthlyRate",
  Fuel: "fuel",
  Transmission: "transmission",
  Brand: "brand",
  Model: "model",
  Segment: "segment",
  Year: "year",
  Kilometers: "kilometers",
  Power: "power",
  Seats: "seats",
  Color: "color",
  Location: "location",
  Dealer: "dealer",
  FreeText: "free",
  Finance: "finance"
};

const kmValuesDropdowns = {
  kmsRange: 15,
  kmsMultiplier: 10000,
  kmsStart: 0
};

const dropdownKm = [...Array(kmValuesDropdowns.kmsRange).keys()].map(i => ({
  key: i * kmValuesDropdowns.kmsMultiplier + kmValuesDropdowns.kmsStart,
  value: `${i * kmValuesDropdowns.kmsMultiplier +
    kmValuesDropdowns.kmsStart} kms`
}));

const addSelected = arr => {
  arr.forEach(el => {
    el.selected = false;
    if (el.children) {
      addSelected(el.children);
    }
  });
};

const state = {
  keys: {},
  dropdownsInitialState: {},
  dropdowns: Object.fromEntries(Object.keys(DropdownType).map(k => [k, []])),
  finance: {},
  extra: {
    tagGroups: {
      technology: [
        {
          value: "test1",
          text: "Example"
        },
        {
          value: "test2",
          text: "Example"
        },
        {
          value: "test3",
          text: "Example"
        },
        {
          value: "test4",
          text: "Example"
        }
      ],
      comfort: [
        {
          value: "test1",
          text: "Example"
        },
        {
          value: "test2",
          text: "Example"
        },
        {
          value: "test3",
          text: "Example"
        },
        {
          value: "test4",
          text: "Example"
        }
      ],
      security: [
        {
          value: "test1",
          text: "Example"
        },
        {
          value: "test2",
          text: "Example"
        },
        {
          value: "test3",
          text: "Example"
        },
        {
          value: "test4",
          text: "Example"
        }
      ],
      media: [
        {
          value: "test1",
          text: "Example"
        },
        {
          value: "test2",
          text: "Example"
        },
        {
          value: "test3",
          text: "Example"
        },
        {
          value: "test4",
          text: "Example"
        }
      ],
      others: [
        {
          value: "test1",
          text: "Example"
        },
        {
          value: "test2",
          text: "Example"
        },
        {
          value: "test3",
          text: "Example"
        },
        {
          value: "test4",
          text: "Example"
        }
      ]
    }
  }
};

const getters = {
  getDefaultDealer: state => state.dropdowns?.dealers[0]?.key,
  getDealers: state => state.dropdowns.dealers,
  getDropdowns: state => state.dropdowns,
  getKey: state => dropdown => {
    return state.keys[dropdown];
  },
  getFinanceDropdown: state => state.finance,
  getTagGroups: state => state.extra.tagGroups,
  getColors: () => [
    {
      key: 1,
      value: "Branco",
      iconColor: "white"
    },
    {
      key: 2,
      value: "Preto",
      iconColor: "black"
    },
    {
      key: 3,
      value: "Cinzento",
      iconColor: "gray"
    },
    {
      key: 4,
      value: "Azul",
      iconColor: "blue"
    },
    {
      key: 5,
      value: "Vermelho",
      iconColor: "red"
    },
    {
      key: 6,
      value: "Verde",
      iconColor: "green"
    }
  ]
};

const mutations = {
  setDropdowns(state, val) {
    state.dropdowns = Object.fromEntries(
      Object.entries(val).map(([k, v]) => [
        k,
        v.map(it => ({
          key: it.id,
          value: it.value,
          selected: false,
          ...(it.title && {
            title: it.title
          }),
          ...(it.models && {
            children: it.models.map(el => ({
              key: el.id,
              value: el.value,
              count: el.count
            }))
          }),
          ...(it.count && {
            count: it.count
          }),
          ...(it.imageURL && {
            imageURL: it.imageURL
          })
        }))
      ])
    );
    state.dropdowns.power = state.dropdowns?.power?.map(({ value }) => ({
      key: value,
      value
    }));
    state.dropdowns.seats = state.dropdowns?.seats?.map(({ value }) => ({
      key: value,
      value
    }));
    state.dropdowns.model = state.dropdowns?.brands
      .flatMap(brand => brand.children)
      .map(model => ({
        key: model.key,
        value: modelNameUppercase(model.value),
        count: model.count
      }));
    state.dropdowns.km = dropdownKm;
    state.dropdowns.years = state.dropdownsInitialState.years;
    const min = state.dropdowns.years[0].min;
    const max = state.dropdowns.years[0].max;
    const dropdownYear = [...Array(max - min + 1).keys()].map(i => ({
      key: i + min,
      value: `${i + min}`
    }));
    state.dropdowns.year = dropdownYear;
  },
  setKeys(state) {
    state.keys = Object.fromEntries(
      Object.entries(state.dropdowns).map(e => [e[0], e[1]?.length])
    );
  },
  setKey(state, dropdown) {
    state.keys[dropdown] = ++state.keys[dropdown] || 1;
  },
  setDropdown(state, { dropdown, val }) {
    if (!Object.values(DropdownType).includes(dropdown)) return;
    var found = [];
    if (val.parent) {
      found = state.dropdowns[val.parent.type].findIndex(
        d => d.key === val.parent.key && d.value === val.parent.value
      );
      var foundChild = state.dropdowns[val.parent.type][
        found
      ].children.findIndex(c => c.key === val.id && c.value === val.value);

      state.dropdowns[val.parent.type][found].children[foundChild] = {
        key: val.id,
        value: val.value,
        selected: false,
        count: val.count
      };
      if (val.imageUrl) {
        state.dropdowns[val.parent.type][found].children[foundChild].imageURL =
          val.imageUrl;
      }
    } else {
      found = state.dropdowns[dropdown].findIndex(
        d => d.key === val.id && d.value === val.value
      );
      state.dropdowns[dropdown][found] = {
        key: val.id,
        value: val.value,
        count: val.count,
        children: val.children,
        selected: val.selected
      };
      if (val.imageUrl) {
        state.dropdowns[dropdown][found].imageURL = val.imageUrl;
      }
    }
  },
  setDropdownParent(state, { dropdown, val }) {
    state.dropdowns[dropdown] = val;
  },
  setFinanceDropdown(state, val) {
    state.finance = val;
  },
  setDropdownInitialState(state, val) {
    state.dropdownsInitialState = val;
  },
  setKmsDropdown(state, val) {
    state.dropdowns.km = val;
  },
  setYearsDropdown(state, val) {
    state.dropdowns.year = val;
  }
};

const actions = {
  getDropdowns({ commit }) {
    // FIXME: call api
    commit("setDropdowns", {});
  },
  async fetchDropdowns({ commit }, val) {
    try {
      const res = val
        ? await getDropdowns(val.conditionId)
        : await getDropdowns();
      commit("setFinanceDropdown", res.data.data.finance);
      addSelected(res.data.data.fuel);
      delete res.data.data.finance;
      commit("setDropdownInitialState", res.data.data);
      commit("setDropdowns", res.data.data);

      commit("setKeys");
    } catch (err) {
      console.error(err);
    }
  },
  resetKms({ commit }) {
    commit("setKmsDropdown", dropdownKm);
  },
  updateDropdown({ commit }, val) {
    commit("setDropdown", { dropdown: val.type, val });
  },
  resetDropdowns({ commit, state }) {
    commit("setDropdowns", state.dropdownsInitialState);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
